import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { Template } from '../templates/template'
import { songs } from './data'

export const SoundsPage: React.FC = () => {
  const [filter, setFilter] = useState<string>('')
  const [filteredSongs, setFilteredSongs] = useState<string[]>([])
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [copiedSong, setCopiedSong] = useState<string>('')

  // Фильтрация по названию и номеру
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase()
    setFilter(value)

    if (!value) {
      // Если фильтр пуст, список песен пуст
      setFilteredSongs([])
    } else if (!isNaN(Number(value))) {
      // Если значение — это число, фильтруем строго по номеру песни
      const songIndex = Number(value) - 1 // Так как нумерация массива начинается с 0
      if (songIndex >= 0 && songIndex < songs.length) {
        setFilteredSongs([songs[songIndex]])
      } else {
        setFilteredSongs([])
      }
    } else {
      // Иначе фильтруем по названию песни
      setFilteredSongs(songs.filter((song) => song.toLowerCase().includes(value)))
    }
  }

  // Копирование текста и отображение уведомления
  const handleCopy = (song: string) => {
    navigator.clipboard.writeText(song)
    setCopiedSong(song)
    setSnackbarOpen(true)
  }

  // Закрытие Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Template>
      <Box sx={{ width: '100%', mx: 'auto', mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Список песен
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Фильтр по названию или номеру"
          value={filter}
          onChange={handleFilterChange}
          sx={{ mb: 2 }}
        />
        <Box
          sx={{
            minHeight: 400, // Минимальная высота, чтобы блок не менялся по размеру
            maxHeight: 400, // Ограничение максимальной высоты с прокруткой
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: 2,
            padding: 1,
          }}
        >
          {filteredSongs.length > 0 ? (
            <List>
              {filteredSongs.map((song, index) => (
                <ListItem key={index} button onClick={() => handleCopy(song)}>
                  <ListItemText primary={`${songs.indexOf(song) + 1}. ${song}`} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
              Ничего не найдено
            </Typography>
          )}
        </Box>
      </Box>

      {/* Snackbar для уведомления о копировании */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Позиция уведомления
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Скопировано: {copiedSong}
        </Alert>
      </Snackbar>
    </Template>
  )
}

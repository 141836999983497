import 'moment/locale/ru'

import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useMedia } from '@/shared/utils/media'

import { $lastDaysReport, $lastDaysReportLoading } from '../../model'
import { getLastDaysReport } from '../../model/events'

moment.locale('ru')

export const Progress: React.FC = () => {
  const [currentDate] = useState<moment.Moment | null>(moment())
  const lastDaysReport = useStore($lastDaysReport)
  const lastDaysReportLoading = useStore($lastDaysReportLoading)

  const { isMobile } = useMedia()

  const totalDaysInMonth = moment(currentDate).daysInMonth()
  const totalDaysInYear = moment(currentDate).isLeapYear() ? 366 : 365

  useEffect(() => {
    getLastDaysReport()
  }, [currentDate])

  const filledInMonthPercentage = ((lastDaysReport?.filledIn_30Days || 0) / 30) * 100
  const filledInYearPercentage = ((lastDaysReport?.filledIn_365Days || 0) / 365) * 100

  const getGradientColor = (value: number) => {
    const red = Math.min(255, (1 - value / 100) * 255)
    const green = Math.min(255, (value / 100) * 255)
    return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, 0)`
  }

  return (
    <Box
      sx={{
        mt: 3,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: isMobile ? 3 : 4,
        justifyContent: 'space-around',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
          {lastDaysReportLoading ? (
            <CircularProgress size={14} />
          ) : (
            ` ${lastDaysReport?.filledIn_30Days}`
          )}{' '}
          из {totalDaysInMonth}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={filledInMonthPercentage}
              sx={{
                height: 15,
                borderRadius: 8,
                backgroundColor: '#d8d8d8',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getGradientColor(filledInMonthPercentage),
                  borderRadius: 8,
                },
              }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              filledInMonthPercentage
            )}%`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
          {lastDaysReportLoading ? (
            <CircularProgress size={14} />
          ) : (
            ` ${lastDaysReport?.filledIn_365Days}`
          )}{' '}
          из {totalDaysInYear}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={filledInYearPercentage}
              sx={{
                height: 15,
                borderRadius: 8,
                backgroundColor: '#d8d8d8',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getGradientColor(filledInYearPercentage),
                  borderRadius: 8,
                },
              }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              filledInYearPercentage
            )}%`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
